import React, { useState, useEffect } from "react"
import Footer from "../../components/Footer"
import Layout from "../../components/layout"

export default function OwnerListPage() {
  return (
    <Layout isInvestor={true}>
      <div className="mfn-widget"></div>
      <Footer />
    </Layout>
  )
}
